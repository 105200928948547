import 'slick-carousel'

function burgerMenu() {
    const burger = document.querySelector(".header-wrap__menu");
    const call = document.querySelector(".header-wrap__call a svg")
    const nav = document.querySelector(".nav");
    const {body} = document;
    
    if (burger && call && nav) {
        burger.addEventListener("click", () => {
            burger.classList.toggle("active");
            call.classList.toggle("active");
            nav.classList.toggle("active");
            body.classList.toggle("lock");
        });
    }
};

$(window).scroll(function(){
    if ($(this).scrollTop() > 50) {
       $('.header').addClass('header--scroll');
    } else {
       $('.header').removeClass('header--scroll');
    }
});

burgerMenu();

function cardsData() {
    fetch('cards.json')
        .then((res) => res.json())
        .then((data) => {
            const cardListEl = document.querySelectorAll(".cards-wrap");
            const tabList = document.querySelectorAll('.projects-wrap');

            data.forEach(function (card) {
                const cardContent = `
                    <li class="cards-wrap__item fade" data-group="${card.category}">
                        <a href="./projects-details.html?id=${card.id}">
                            <div class="btn btn--light cards-wrap__btn">
                                <span>${card.name} 👉</span>
                                <span>${card.reach}</span>
                            </div>
                            <img src="./img/${card.img}" alt="">
                        </a>
                    </li>
                `;

                cardListEl.forEach(function (e) {
                    e.innerHTML += cardContent;
                });

                tabList.forEach(function (e) {
                    e.innerHTML += cardContent;
                });
            });
        })
};

cardsData();

function cardsDetail() {
    fetch('cards.json')
        .then((res) => res.json())
        .then((data) => {
            const currentProjectId = Number(new URL(window.location.href).searchParams.get('id'))
            const currentProject = data.find(projectToFind => projectToFind.id === currentProjectId)
            const projectDetailsRef = document.querySelector('.projects-d');

            projectDetailsRef.innerHTML += `
                <div class="section projects-d__wrap actual">
                    <img src="./img/${currentProject.idImg}" class="projects-d__img" alt="">
                    <h2>${currentProject.name}</h2>
                    <span>${currentProject.reach}</span>
                </div>
                <div class="section projects-d__descr">
                    <h3>${currentProject.descr}</h3>
                    <div class="projects-d__available">
                        <h4>Available now 🔥</h4>
                        <a href="/" class="btn btn--black projects-d__btn"> 
                            <img src="./icons/${currentProject.available.ex1.icon}"/>
                            <span>${currentProject.available.ex1.text}</span>
                        </a>
                        <a href="/" class="btn btn--black projects-d__btn"> 
                            <img src="./icons/${currentProject.available.ex2.icon}"/>
                            <span>${currentProject.available.ex2.text}</span>
                        </a>
                    </div>
                    <div class="projects-d__about">
                        <h4>About project ✌️</h4>
                        <p>${currentProject.about}</p>
                    </div>
                </div> 
                <div class="projects-d__carousel">
                    <ul class="projects-d__carousel-wrap">

                    </ul>
                    <ul class="projects-d__carousel-nav">

                    </ul>
                    <span>Thank for attention <br> 😎</span>
                    <section class="width--full padd--tb-xs">
                        <div class="container">
                            <div class="devider">
                                <hr>
                                <img src="./img/logo.svg" alt="">
                                <hr>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="section projects-d__other">
                    <h2>Other projects</h2>
                    <ul class="projects-d__other-list">
                    </ul>
                    <div class="skills-serv__more">
                        <a href="projects.html" class="btn btn--black">All projects 🔥</a>
                        <a href="contacts.html" class="btn btn--transparent btn--bg">Contact us 👋</a>
                    </div>
                </div>
            `;

            const list = document.querySelector(".projects-d__carousel-wrap");
            const navlist = document.querySelector(".projects-d__carousel-nav");

            currentProject.slides.forEach((item) => {
                const slideListItem = document.createElement("li");
    
                const image = document.createElement("img");
                image.src = item.name

                slideListItem.appendChild(image)

                list.appendChild(slideListItem);
                const slideListItemClone = slideListItem.cloneNode(true)
                navlist.appendChild(slideListItemClone)
            });


            const slideWrapper = document.querySelectorAll('.projects-d__other-list');

            data.forEach(function (slides) {
                const slideContent = `
                    <li class="projects-d__other-item">
                        <a href="./projects-details.html?id=${slides.id}">
                            <img src="./img/${slides.img}">
                            <div class="projects-d__other-info">
                                <span>${slides.reach}</span>
                                <h3>${slides.name}<br>👉</h3>
                            </div>
                        </a>
                    </li>
                `;

                slideWrapper.forEach((e) => {
                    e.innerHTML += slideContent;
                });
            });

            $('.projects-d__carousel-wrap').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerPadding: false,
                autoplay: false,
                centerMode: true,
                autoplaySpeed: 3500,
                pauseOnHover: true,
                dots: false,
                arrows: true,
                asNavFor: '.projects-d__carousel-nav',
            });

            $('.projects-d__carousel-nav').slick({
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                centerPadding: '5px',
                centerMode: true,
                pauseOnHover: true,
                asNavFor: '.projects-d__carousel-wrap',
            });

            const projBtn = document.querySelectorAll('.projects-d__btn span');

            projBtn.forEach((item) => {
                if (item.innerHTML === '') {
                    document.querySelector('.projects-d__btn').classList.add('hide');
                }
            });
        })
};

cardsDetail();

function Sliders() {
    $('.skills-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '5px',
        autoplay: false,
        centerMode: true,
        autoplaySpeed: 3500,
        pauseOnHover: true,
        dots: true,
    });
}

Sliders();

function Tabs() {
    function changeTab(event) {
        document.getElementsByClassName("toggled")[0].classList.remove("toggled");
        event.target.classList.add("toggled");
        event.preventDefault();

        const group = event.target.id;
        const state = document.querySelector(".all");

        state.addEventListener('click', () => {
            document.querySelectorAll('.cards-wrap__item')
                .forEach((el) => el.classList.remove('hidden') && el.classList.add('fade'));
        });

        document
            .querySelectorAll(".cards-wrap__item")
            .forEach((el) => el.classList.add("hidden"));

        document.querySelectorAll(`[data-group='${group}']`).forEach((el) => {
            el.classList.remove("hidden");
            el.classList.add("fade");
        });
    };

    document.querySelectorAll(".projects-tabs__item").forEach((el) => {
        el.addEventListener("click", changeTab)
    });
};

Tabs();